









import { Component, Prop, PropSync, Vue } from "vue-property-decorator"
import { ICampaignListData } from "@/api/types"

@Component
export default class extends Vue {
	@PropSync("visible") private syncedVisible!: boolean
	@Prop() private readonly campaign!: ICampaignListData | null
}
