














import { scrollTo } from "@/utils/scroll-to";
interface PaginationEvent {
  id: number;
  pageSize: number;
}
const __sfc_main = {};
__sfc_main.props = {
  page: {
    key: "page",
    required: true,
    type: Number
  },
  pageSize: {
    key: "pageSize",
    required: true,
    type: Number
  },
  total: {
    key: "total",
    required: true,
    type: Number
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  function handleSizeChange(value: number) {
    emit("pagination", {
      page: props.page,
      pageSize: value
    });
    scrollTo(0, 800);
  }

  function handleCurrentChange(value: number) {
    emit("pagination", {
      page: value,
      pageSize: props.pageSize
    });
    scrollTo(0, 800);
  }

  return {
    handleSizeChange,
    handleCurrentChange
  };
};

export default __sfc_main;
