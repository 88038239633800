











import { ref as _ref } from "@vue/composition-api";
import router from "@/router";
import Campaigns from "@/select2/campaign";
import { queryToList, range } from "@/utils";
import StatusCard from "./StatusCard.vue";
type StatusData = {
  id: number;
  count: number;
};
type StatusState = {
  [key: number]: boolean;
};
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;

  let loading = _ref(true);

  let statusData = _ref((range(1, 8).map(s => ({
    id: s,
    count: 0
  })) as StatusData[]));

  let state = _ref((Object.fromEntries(queryToList(router.currentRoute.query.status).map(s => [s, true])) as StatusState));

  async function load() {
    loading.value = true;
    Campaigns.listAction<StatusData>("status_summary").then(data => {
      statusData.value = Object.entries(data).map(([k, v]) => ({
        id: parseInt(k),
        count: v
      }));
      loading.value = false;
    });
  }

  load();

  function setStatus({
    status,
    active
  }) {
    state.value[status] = active;
    const activeStates = Object.entries(state.value).filter(([_, active]) => active).map(([s, _]) => parseInt(s));
    emit("change", activeStates);
  }

  return {
    loading,
    statusData,
    setStatus
  };
};

__sfc_main.components = Object.assign({
  StatusCard
}, __sfc_main.components);
export default __sfc_main;
