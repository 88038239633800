








import { ref as _ref, computed as _computed } from "@vue/composition-api";
import { campaignStatusDisplay } from "@/select";
import { Status } from "@/select/campaign";
import router from "@/router";
interface Props {
  count: number;
  status: Status;
}
const __sfc_main = {};
__sfc_main.props = {
  count: {
    key: "count",
    required: true,
    type: Number
  },
  status: null
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const status = props.status;
  const emit = __ctx.emit;

  let active = _ref((router.currentRoute.query.status || []).includes(status.toString()));

  let name = _computed(() => campaignStatusDisplay(status));

  function toggleStatus(status: number) {
    active.value = !active.value;
    emit("change", {
      status,
      active: active.value
    });
  }

  return {
    props,
    status,
    active,
    name,
    toggleStatus
  };
};

export default __sfc_main;
