





















import { Component, Prop, PropSync, Vue } from "vue-property-decorator"
import { CampaignData, CampaignStatus, updateCampaign } from "@/select"
import { getLineItems } from "@/select/api"

enum SSPStatus {
	Waiting,
	Loading,
	Success,
	Error,
}

@Component({
	name: "ReviewDialog",
})
export default class extends Vue {
	@PropSync("visible") syncedVisible!: boolean
	@Prop({ default: { name: "Unknown" } }) campaign!: CampaignData | null
	private message = ""
	private approved: boolean | null = null
	private sync: boolean = true

	mounted() {
		this.clear()
	}

	submit() {
		this.$emit("submit", {
			approved: this.approved,
			message: this.message,
			sync: this.sync,
		})
		// if (this.approved) {
		//   if (this.campaign == null) return
		//   const res = await updateCampaign(
		//     this.campaign.id,
		//     { status: CampaignStatus.Active },
		//     true,
		//     this.sync
		//   )
		//   if (this.campaign == null) return
		//   this.clear()
		//   this.syncedVisible = false
		// }
	}

	private handleClose(done) {
		this.clear()
		this.syncedVisible = false
		done()
	}

	private clear() {
		this.approved = null
		this.message = ""
	}

	get title() {
		if (this.campaign == null) return ""
		return "Review " + this.campaign.name
	}
}
