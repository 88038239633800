




























































































































































import { ref as _ref, computed as _computed } from "@vue/composition-api";
import { ICampaignListData } from "@/api/types";
import Pagination from "@/components/Pagination/index2.vue";
import ReviewDialog from "./components/ReviewDialog.vue";
import StatusCardManager from "./components/StatusCardManager.vue";
import SubmitDialog from "./components/SubmitDialog.vue";
import { runReport } from "@/api/reporting";
import { Status, statusDisplay } from "@/select/campaign";
import { deleteCampaign, setCampaignActive, reviewCampaign, submitCampaign } from "@/select/api";
import { UserModule } from "@/store/modules/user";
import { asCurrency, asNumber, asPercentage, errorMessage, formatCell, handleErrorResponse } from "@/utils";
import { Error, ErrorCode } from "@/utils/constants";
import Campaigns, { CampaignData, Params } from "@/select2/campaign";
import router from "@/router";
import { Input, Message } from "element-ui";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const statusTypeMap = new Map([[Status.Active, "success"], [Status.Ended, "info"]]);

  function reportBody(campaigns: any[]) {
    return {
      dimensions: ["campaign_id"],
      metrics: ["impressions", "clicks", "ctr", "vast_complete", "completion_rate", "gross_spend", "viewability"],
      filters: {
        campaigns
      }
    };
  }

  let campaigns = _ref(([] as CampaignData[]));

  let loading = _ref(true);

  let loadingReport = _ref(true);

  let submitDialogVisible = _ref(false);

  let reviewDialogVisible = _ref(false);

  let activeCampaign = _ref((null as ICampaignListData | null));

  let listQuery = _ref(new Params(router.currentRoute.query));

  let count = _ref(0);

  let controller = new AbortController();

  let searchRef: InstanceType<typeof Input> = _ref(null);

  async function load() {
    loading.value = true;
    const data = await Campaigns.list(listQuery.value.toRouterQuery(), controller.signal);
    campaigns.value = data.results;
    count.value = data.count;
    loading.value = false;
    const ids = data.results.map(c => c.id);
    runReport(reportBody(ids)).then(res => {
      campaigns.value = campaigns.value.map(c => {
        const stats = res.data.find(row => row.campaign_id === c.id);
        return stats !== undefined ? { ...c,
          ...stats
        } : c;
      });
      loadingReport.value = false;
    });
  }

  onMounted(() => {
    load();
    nextTick(() => {
      searchRef.value.focus();
    });
  });

  function reloadCampaigns() {
    router.push({
      query: listQuery.value.toRouterQuery()
    });
    load();
  }

  async function search() {
    router.push({
      query: listQuery.value.toRouterQuery()
    });
    listQuery.value.page = 1;
    controller.abort();
    controller = new AbortController();
    load();
  }

  function handleDropdown(e: string, row: any) {
    if (e !== "delete") if (e === "edit") router.push(`/campaigns/{row.id}/edit`);
    if (e === "pause") setActive(false, row);
    if (e === "submit") submit(row);
    if (e === "review") review(row);
    if (e === "resume") setActive(true, row);
    if (e === "delete") handleDelete(row);
    if (e === "duplicate_with_creatives") duplicate(row.id, true);
    if (e === "duplicate_without_creatives") duplicate(row.id, false);
  }

  async function duplicate(id: number, duplicate_creatives: boolean) {
    try {
      const newId = await Campaigns.request({
        url: `${id}/duplicate/`,
        method: "POST",
        data: {
          duplicate_creatives
        }
      });
      router.push(`/campaigns/edit/${newId}`);
    } catch (e) {
      Message.error(handleErrorResponse(e));
    }
  }

  async function setActive(active: boolean, row: any) {
    try {
      await setCampaignActive(active)(row.id);
      Message.success(`Campaign '${row.name}' ${active ? "resumed" : "paused"}`);
      row.status = active ? Status.Active : Status.Paused;
    } catch (e) {
      Message.error(handleErrorResponse(e));
    }
  }

  function review(row: any) {
    activeCampaign.value = row;
    reviewDialogVisible.value = true;
  }

  async function handleReview({
    approved,
    message,
    sync
  }: {
    approved: boolean;
    message: string;
    sync: boolean;
  }) {
    reviewDialogVisible.value = false;
    loading.value = true;

    if (activeCampaign.value === null) {
      Message.error(errorMessage(Error.Unknown, ErrorCode.ActiveCampaignNull));
      return;
    }

    try {
      const res = await reviewCampaign(approved, message, sync)(activeCampaign.value.id);
      Message.success(`'${activeCampaign.value.name}' ${approved ? "approved" : "rejected"}`);
      activeCampaign.value.status = res.data.status;
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      loading.value = false;
    }
  }

  async function handleDelete(row: any) {
    loading.value = true;

    try {
      await deleteCampaign(row.id);
      Message.success(`'${row.name}' deleted`);
      campaigns.value = campaigns.value.filter(c => c !== row);
    } catch (e) {
      console.error(e);
      handleErrorResponse(e);
    } finally {
      loading.value = false;
    }
  }

  function filterStatus(s: Status[]) {
    listQuery.value.status = s;
    listQuery.value.page = 1;
    reloadCampaigns();
  }

  function submit(row: any) {
    activeCampaign.value = row;
    submitDialogVisible.value = true;
  }

  async function submitActive() {
    submitDialogVisible.value = false;

    if (activeCampaign.value === null) {
      Message.error("Could not determine which campaign to submit");
      return;
    }

    loading.value = true;

    try {
      const res = await submitCampaign(activeCampaign.value.id);
      Message.success(`Submitted campaign '${activeCampaign.value.name}'`);
      activeCampaign.value.status = res.data.status;
    } catch (e) {
      if ((e as any)?.response?.data?.detail) {
        Message.error((e as any).response.data.detail);
      } else {
        Message.error(`Could not submit campaign '${activeCampaign.value.name}': Unknown error`);
      }
    } finally {
      loading.value = false;
    }
  }

  function statusType(s: Status) {
    return statusTypeMap.get(s) || "";
  }

  let isEditor = _computed(() => {
    return UserModule.roles.includes("campaign editor") || UserModule.roles.includes("admin");
  });

  const isAdmin = UserModule.roles.includes("admin");

  function handlePagination({
    page,
    pageSize
  }) {
    listQuery.value.page = page;
    listQuery.value.page_size = pageSize;
    reloadCampaigns();
  }

  return {
    Status,
    statusDisplay,
    asCurrency,
    asNumber,
    asPercentage,
    formatCell,
    router,
    campaigns,
    loading,
    loadingReport,
    submitDialogVisible,
    reviewDialogVisible,
    activeCampaign,
    listQuery,
    count,
    searchRef,
    search,
    handleDropdown,
    review,
    handleReview,
    handleDelete,
    filterStatus,
    submit,
    submitActive,
    statusType,
    isEditor,
    isAdmin,
    handlePagination
  };
};

__sfc_main.components = Object.assign({
  Pagination,
  ReviewDialog,
  StatusCardManager,
  SubmitDialog
}, __sfc_main.components);
export default __sfc_main;
