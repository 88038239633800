import request from "@/utils/request"

export const getReport = (params: any) =>
	request({
		url: "/report",
		method: "get",
		params,
	})

export const runReport = (data: any) =>
	request({
		url: "/report",
		method: "post",
		data,
	})

export const downloadReport = (data: any) =>
	request({
		url: "/report",
		method: "post",
		data,
		responseType: "blob",
	})
